<template>
  <div class="not-found-page-wrapper">
    <div class="error-text">404</div>
    <p class="error-text-sec">Страница не найдена</p>
    <router-link class="btn btn-accent" to="/">Вернуться на главную</router-link>
  </div>
</template>

<script>
export default {
  name: 'not-found',
}
</script>

<style lang="scss" scoped>
.not-found-page-wrapper {
    width: 100%;
    margin: 76px auto;
    padding: 100px 0 80px;
    color: var(--color);
    display: flex;
    justify-items: center;
    align-content: center;
    flex-direction: column;
    align-items: center;

  .error-text {
    font-size: 82px;
  }

  .error-text-sec {
    font-size: 20px;
  }

}
</style>